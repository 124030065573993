import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import ArtistsView from '../views/ArtistsView.vue'
import PartnersView from '../views/PartnersView.vue'
import ArtistDetailsView from '../views/ArtistDetailsView.vue'
import ProtocoleView from '@/views/ProtocoleView.vue'

const routes = [
  {
    path: '/',
    name: 'accueil',
    component: HomeView
  },
  {
    path: '/protocole',
    name: 'protocole',
    component: ProtocoleView
  },
  {
    path: '/artistes',
    name: 'artistss',
    component: ArtistsView
  },
  {
    path: '/a-propos',
    name: 'a-propos',
    component: PartnersView
  },
  {
    path: '/artistes/:id',
    name: 'artiste',
    component: ArtistDetailsView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
