<template>
  <div class="pop-up-stack">
    <div class="main-content">
      <nav>
        <div class="nav-options">
          <router-link to="/">
            <div class="container">
              <div class="cube">
                <div class="side front"></div>
                <div class="side back"></div>
                <div class="side left"></div>
                <div class="side right"></div>
                <div class="side top"></div>
                <div class="side bottom"></div>
              </div>
            </div>
          </router-link>
          <MqResponsive target="lg+">
            <div style="display: flex">
              <router-link to="/protocole" class="nav-link"
                >protocole</router-link
              >
              <router-link to="/artistes" class="nav-link"
                >les artistes</router-link
              >
              <router-link to="/a-propos" class="nav-link"
                >à propos</router-link
              >
              <div class="sound-player">
                <img
                  v-if="isPlaying"
                  class="speaker"
                  src="@/assets/sound.png"
                  @click="playSound"
                />
                <img
                  v-if="!isPlaying && !alreadyPlayed"
                  class="speaker"
                  src="@/assets/mute.png"
                  @click="playSound"
                />
              </div>
            </div>
          </MqResponsive>
          <MqResponsive target="md-">
            <div style="display: flex">
              <div class="sound-player" style="margin-right: 40px">
                <img
                  v-if="isPlaying"
                  class="speaker"
                  src="@/assets/sound.png"
                  @click="playSound"
                />
                <img
                  v-if="!isPlaying && !alreadyPlayed"
                  class="speaker"
                  src="@/assets/mute.png"
                  @click="playSound"
                />
              </div>
              <div class="hamburger-menu">
                <input id="menu__toggle" type="checkbox" />
                <label class="menu__btn" for="menu__toggle">
                  <span></span>
                </label>
                <div class="menu__box">
                  <div class="menu__item">
                    <router-link to="/protocole">protocole</router-link>
                  </div>
                  <div class="menu__item">
                    <router-link to="/artistes">les artistes</router-link>
                  </div>
                  <div class="menu__item">
                    <router-link to="/a-propos">à propos</router-link>
                  </div>
                </div>
              </div>
            </div>
          </MqResponsive>
        </div>
      </nav>
      <router-view />
    </div>
    <FinishPopup/>
  </div>
</template>

<script setup>
import { MqResponsive } from "vue3-mq";
import FinishPopup from './components/FinishPopup.vue';

import { ref } from "vue";
import kilian from "@/assets/kilian.mp3";

const audio = new Audio(kilian);
const isPlaying = ref(false);

function playSound() {
  if (isPlaying.value) {
    audio.pause();
    isPlaying.value = false;
  } else {
    audio.play();
    isPlaying.value = true;
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Syne+Tactile&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Krub:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

#app {
  font-family: "Krub", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}

body {
  overflow-anchor: none;
  margin: 0;
}

nav {
  padding: 30px;
  display: flex;
}

.sound-player {
  cursor: pointer;
  align-content: center;
}

.nav-link {
  margin: 50px;
}

.nav-options {
  font-family: "Lexend", Avenir, Helvetica, Arial, sans-serif;
  font-size: 34px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.logo {
  height: 80px;
}

.speaker {
  align-content: bottom;
  align-self: bottom;
  align-items: bottom;
  width: 40px;
  margin-top: 58px;
  margin-bottom: 50px;
}

nav a {
  color: black;
  text-decoration: none;
}

nav a.router-link-exact-active {
  color: black;
  text-decoration: underline;
}

.container {
  width: 100px;
  height: 100px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 320px;
  perspective-origin: top right;
}

@keyframes cubeRotate {
  from {
    transform: rotateY(0deg) rotateX(720deg) rotateZ(0deg);
  }
  to {
    transform: rotateY(360deg) rotateX(0deg) rotateZ(360deg);
  }
}

/* The child element, with 3D tranforms preserved */
.cube {
  animation: cubeRotate 10s linear infinite;
  position: relative;
  width: 50px;
  height: 50px;
  transform-style: preserve-3d;
}

/* The sides of the cube, absolutely positioned */
.side {
  position: absolute;
  width: 100%;
  height: 100%;
  /* opacity: 0.9; */
  border: 0.5px solid black;
}

.front {
  background-color: white;
  background-image: url("@/assets/atfu.png");
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  transform: translateZ(25px);
}

.back {
  background-color: white;
  transform: translateZ(-25px);
  background-image: url("@/assets/togeather-reverse.png");
  background-size: 55%;
  background-position: center;
  background-repeat: no-repeat;
}

.left {
  background-color: white;
  transform: rotateY(90deg) translateZ(25px);
  background-image: url("@/assets/togeather.png");
  background-size: 55%;
  background-position: center;
  background-repeat: no-repeat;
}
.right {
  background-color: white;
  transform: rotateY(-90deg) translateZ(25px);
  background-image: url("@/assets/atfu.png");
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
}
.top {
  background-color: white;
  transform: rotateX(90deg) translateZ(25px);
  background-image: url("@/assets/atfu.png");
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
}
.bottom {
  background-color: white;
  transform: rotateX(-90deg) translateZ(25px);
  background-image: url("@/assets/togeather.png");
  background-size: 55%;
  background-position: center;
  background-repeat: no-repeat;
}

#menu__toggle {
  opacity: 0;
}
#menu__toggle:checked + .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked + .menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
}
#menu__toggle:checked + .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
  right: 0 !important;
}
.menu__btn {
  z-index: 4;
  position: fixed;
  width: 26px;
  height: 26px;
  cursor: pointer;
  margin-top: 72px;
  right: 40px;
}
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: black;
  transition-duration: 0.25s;
}
.menu__btn > span::before {
  content: "";
  top: -8px;
}
.menu__btn > span::after {
  content: "";
  top: 8px;
}
.menu__box {
  font-size: 26px;
  z-index: 3;
  text-align: left;
  margin: 0;
  display: block;
  position: fixed;
  top: 140px;
  right: -100%;
  padding: 0;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  transition-duration: 0.25s;
}
.menu__item {
  justify-content: center;
  justify-self: center;
  /* display: block;
  padding: 12px 24px;
  color: black;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  transition-duration: 0.25s; */
  background-color: white;
}

.menu__item:hover {
  background-color: white;
}
</style>
