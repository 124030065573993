<template>
    <div>
      <ArtistDetails />
    </div>
  </template>
    
  <script>
    import ArtistDetails from "@/components/ArtistDetails.vue";
  
    export default {
      name: "ArtistDetailsView",
      components: {
        ArtistDetails
      },
    }
  </script>