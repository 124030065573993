<template>
  <div class="background">
    <div style="padding-top: 200px; padding-bottom: 100px">
      <div v-if="protocole" class="protocole-container">
        <div style="display: flex">
          <h2>Waall #5 protocole</h2>
          <div class="note-hole"></div>
        </div>
        <p class="protocole-text">
          <span v-html="protocole.content" />
        </p>
      </div>
    </div>
  </div>
</template> 

<script setup>
import { ref } from "vue";

const server = process.env.VUE_APP_SERVER;
const token = process.env.VUE_APP_BEARER_TOKEN;

const protocole = ref(null);
fetch(`${server}/api/protocole`, {
  method: "get",
  headers: {
    "content-type": "application/json",
    Authorization: token,
  },
})
  .then((response) => response.json())
  .then((data) => (protocole.value = data.data.attributes));
</script>
  
<script>
export default {
  name: "HomePage",
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .home-text {
  } */
nav {
  margin: 0px;
  padding: 0px;
  position: absolute;
  width: 100%;
}
.nav-options {
  padding: 24px;
  width: 100%;
  align-items: center;
}

.note-hole {
  border-radius: 100px;
  height: 20px;
  width: 20px;
  border: 1px black solid;
  margin: 4px 4px 4px auto;
}

.protocole-container {
  border: 1px solid black;
  text-align: left;
  background-color: white;
  margin: auto;
  width: 80%;
  padding: 16px;
}

.protocole-text {
  font-size: 18px;
  justify-self: right;
}

p {
  padding: 0px 60px;
  padding-bottom: 4px;
}

h2 {
  padding-left: 30px;
  padding-bottom: 20px;
  padding-top: 8px;
}

.background {
  margin-top: -160px;
  background-image: url("../assets/fond-vert.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: calc(100vh + 60px);
}

@media (max-width: 860px) {
  p {
    padding: 0px 30px;
  }
}
</style>