<template>
  <div>
    <Artists />
  </div>
</template>
  
<script>
  import Artists from "@/components/Artists.vue";

  export default {
    name: "ArtistsView",
    components: {
      Artists
    },
  }
</script>