<template>
    <div v-if="isVisible" class="popup-overlay">
      <div class="popup-content">
        <button class="close-btn" @click="closePopup">×</button>
        <div class="popup-body">
        <h1>Waall #5 c'est terminé !</h1>
        <p>
          Nous avons tablé sur le goût des artistes contemporain·es pour créer une exposition qui présentait aux enchères 17 pièces originales, soumises au troc au grand public. En plein dans le mille, l'expo fut un succès : toutes les œuvres ont trouvé acquéreur·euses samedi soir. 𝟑 𝐬𝐞𝐦𝐚𝐢𝐧𝐞𝐬 𝐚𝐮 𝐌𝐞𝐱𝐢𝐪𝐮𝐞, 𝐮𝐧𝐞 𝐬𝐞𝐦𝐚𝐢𝐧𝐞 𝐜𝐡𝐞𝐳 𝐥'𝐡𝐚𝐛𝐢𝐭𝐚𝐧𝐭 𝐚̀ 𝐇𝐨𝐧𝐠 𝐊𝐨𝐧𝐠 𝐨𝐮 𝐞𝐧𝐜𝐨𝐫𝐞 𝐮𝐧𝐞 𝐫𝐞́𝐬𝐢𝐝𝐞𝐧𝐜𝐞 𝐝𝐚𝐧𝐬 𝐥𝐚 𝐜𝐚𝐦𝐩𝐚𝐠𝐧𝐞 𝐧𝐨𝐫𝐦𝐚𝐧𝐝𝐞... 𝟐𝟒𝟕 𝐩𝐫𝐨𝐩𝐨𝐬𝐢𝐭𝐢𝐨𝐧𝐬 𝐝𝐞 𝐭𝐫𝐨𝐜 𝐭𝐨𝐮𝐭𝐞𝐬 𝐥𝐞𝐬 𝐩𝐥𝐮𝐬 𝐟𝐨𝐥𝐥𝐞𝐬 𝐥𝐞𝐬 𝐮𝐧𝐞𝐬 𝐪𝐮𝐞 𝐥𝐞𝐬 𝐚𝐮𝐭𝐫𝐞𝐬 et plus de 600 visiteur·euses ont été enregistré·es !
        </p>
        <p>
          Si vous avez loupé le coche ou bien si vous en voulez toujours plus, il y a maintenant Atfu Parade, un site sur lequel vous découvrirez tous les mois 3 artistes parmi les plus plébiscité·e sur l’application de troc Atfu. Vous pourrez demander à les rencontrer, leur acheter les pièces présentées ou leur proposer un troc. 
        </p>
        <p>
          La première édition arrive en juillet, pour se pré inscrire, c’est par ici :
        </p>
        <button onclick="window.location.href='https\://parade.atfu.io/waiting-list'"><img src="@/assets/logo_atfu_parade.webp" height="60px"></button>
      </div>
    </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FinishPopup',
    props: {
      visible: {
        type: Boolean,
        required: true,
      }
    },
    data() {
      return {
        isVisible: true
      };
    },
    watch: {
      visible(newVal) {
        this.isVisible = newVal;
      }
    },
    methods: {
      closePopup() {
        this.isVisible = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ajout d'une valeur z-index pour s'assurer que le popup est au-dessus */
  }
  
  .popup-content {
    max-height: 80%;
    max-width: 800px;
    background: white;
    padding: 20px;
    margin: 50px;
    padding-bottom: 30px;
    border-radius: 8px;
    position: relative;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .parade-button {
  animation: flicker 1.5s infinite alternate;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: normal;
  font-size: 24px;
  padding: 24px;
  border: 1px solid #33ff00;
  /* border: 2px solid #0500ff; */
  background-color: white;
  grid-column: 2;
  font-weight: bold;
  max-width: 250px;
  max-height: 80px;
  justify-self: center;
  align-self: center;
}

@keyframes flicker {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    box-shadow: 0 0 2px #fff, 0 0 5px #fff, 0 0 5px #33ff00, 0 0 10px #33ff00,
      0 0 20px #33ff00, 0 0 15px #33ff00, 0 0 10px #33ff00, 0 0 5px #33ff00;
  }
  20%,
  24%,
  55% {
    box-shadow: none;
  }
}

p {
  padding: 12px;
}

h1 {
  padding: 6px;
}

.popup-body {
  max-height: 70vh; /* Ajustement pour prendre en compte le padding */
  overflow-y: auto;
}
  </style>