<template>
  <div class="background">
    <div style="padding-top: 160px">
      <div class="home-text" v-if="content">
        <span v-html="content" />
      </div>
    </div>
    <router-link to="/artistes">
      <div class="see-selection-button">découvrir la sélection</div>
    </router-link>
    <div class="wrapper">
      <div class="marquee">
        <p v-html="banner" />
        <p>&nbsp;</p>
        <p v-html="banner" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const server = process.env.VUE_APP_SERVER;
const token = process.env.VUE_APP_BEARER_TOKEN;

const content = ref(null);
const banner = ref(null);
fetch(`${server}/api/accueil`, {
  method: "get",
  headers: {
    "content-type": "application/json",
    Authorization: token,
  },
})
  .then((response) => response.json())
  .then((data) => {
    content.value = data.data.attributes.content;
    banner.value = data.data.attributes.banner;
  });
</script>

<script>
export default {
  name: "HomePage",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home-text {
  z-index: 1;
  padding-left: 80px;
  text-align: left;
  font-family: "Syne Tactile", system-ui;
  font-weight: 400;
  font-style: normal;
  font-size: 36px;
  height: calc(100vh - 170px);
  overflow: scroll;
}
nav {
  z-index: 100;
  margin: 0px;
  padding: 0px;
  position: absolute;
  width: 100%;
  min-height: 140px;
}
.nav-options {
  padding: 24px;
  width: 100%;
  align-items: center;
}
.see-selection-button {
  animation: flicker 1.5s infinite alternate;
  color: black;
  display: flex;
  padding: 0px 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 80px;
  background-color: white;
  z-index: 2;
  font-size: 24px;
  border: 3px solid #33ff00;
}

.wrapper {
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 150;
  bottom: 50px;
}

.marquee {
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  background-color: #d5ff2f;
  animation: marquee 15s linear infinite;
  animation-delay: 0.5s;
}

.marquee p {
  display: inline-block;
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes flicker {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    box-shadow: 0 0 2px #fff, 0 0 5px #fff, 0 0 10px #33ff00, 0 0 20px #33ff00,
      0 0 40px #33ff00, 0 0 25px #33ff00, 0 0 15px #33ff00, 0 0 15px #33ff00;
  }
  20%,
  24%,
  55% {
    box-shadow: none;
  }
}

.background {
  margin-top: -208px;
  background-image: url("../assets/fond.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
}

@media (max-width: 860px) {
  .home-text {
    padding: 0px 12px;
  }
}
</style>
