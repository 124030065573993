<template>
  <div>
    <Partners />
  </div>
</template>
  
  <script>
import Partners from "@/components/Partners.vue";

export default {
  name: "PartnersView",
  components: {
    Partners
  },
};
</script>