<template>
  <div class="background">
    <div class="partner-content">
      <h3>
        <strong>Waall #5 est une exposition en deux temps :<br><br>
        1/ enchères en ligne sur ce site<br>
        2/ exposition des œuvres et annonce des résultats à la galerie Au Roi.<br><br></strong>
        31 mai - 1er juin 2024, 13h-19h<br />73 rue de la fontaine au roi, 75011 - annonce des résultats le 1er juin, lors de la Nuit Blanche à 22h<br /><br />
      </h3>
      <h2>Waall #5 a été imaginée par</h2>
      <div class="partner-container">
        <MqResponsive target="md-">
          <div style="margin: 46px">
            <img src="../assets/gif-atfu.gif" alt="gifatfu" />
          </div>
        </MqResponsive>
        <p class="right-text">
          <span v-html="content_atfu" />
        </p>
        <MqResponsive target="lg+">
          <div style="margin: 46px; margin-left: 0px">
            <img src="../assets/gif-atfu.gif" alt="gifatfu" />
          </div>
        </MqResponsive>
      </div>
      <div class="partner-container">
        <MqResponsive target="lg+">
          <div style="margin: 46px; margin-left: 92px; margin-right: 0px">
            <img
              src="../assets/togaether_presentation.gif"
              alt="giftogaether"
            />
          </div>
        </MqResponsive>
        <MqResponsive target="md-">
          <div style="margin: 46px">
            <img
              src="../assets/togaether_presentation.gif"
              alt="giftogaether"
            />
          </div>
        </MqResponsive>
        <p class="left-text">
          <span v-html="content_togaether" />
        </p>
      </div>
    </div>

    <h2>avec le soutien de</h2>
    <div class="partners-logos">
      <img class="logo" alt="logo" src="../assets/au-roi.png" />
      <img class="logo" alt="logo" src="../assets/nuit-blanche.png" />
      <img class="logo" alt="logo" src="../assets/pernod-ricard.png" />
    </div>
  </div>
</template>

<script setup>
import { MqResponsive } from "vue3-mq";
import { ref } from "vue"; 

const server = process.env.VUE_APP_SERVER;
const token = process.env.VUE_APP_BEARER_TOKEN;

const content_atfu = ref(null);
const content_togaether = ref(null);
fetch(`${server}/api/partenaire`, {
  method: "get",
  headers: {
    "content-type": "application/json",
    Authorization: token,
  },
})
  .then((response) => response.json())
  .then((data) => {
    content_togaether.value = data.data.attributes.togaethercontent;
    content_atfu.value = data.data.attributes.atfucontent;
  });
</script>
    
<script>
export default {
  name: "PartnersPage",
};
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
  margin-left: 80px;
  text-align: left;
  font-size: 36px;
  font-family: "Lexend";
  font-weight: normal;
}

.partners-logos img {
  width: 200px;
  height: auto;
  margin: 46px;
}

.partner-container img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.partners-logos {
  display: flex;
  justify-content: center; /* Centrer horizontalement */
  align-items: center; /* Utiliser toute la hauteur de la div parent */
}

.background {
  margin-top: -160px;
  background-image: url("../assets/fond-vert.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  max-height: 270vh;
}

.partner-container {
  justify-items: center;
  align-items: top;
  align-content: top;
  display: flex;
}

.partner-content {
  padding: 180px 0px 40px 0px;
}

p {
  font-family: "Syne Tactile";
  font-size: 24px;
}

.left-text {
  text-align: left;
  margin: 40px 120px 40px 40px;
}

.right-text {
  text-align: right;
  margin: 40px 40px 40px 120px;
}

.mobile-gif {
  visibility: hidden;
}

h3 {
  margin-left: 80px;
  text-align: left;
  font-weight: normal;
}

@media (max-width: 991px) {
  .partners-logos {
    display: block;
  }
  .partner-container {
    display: block;
  }
}

@media (max-width: 860px) {
  .right-text,
  .left-text {
    margin: 40px;
  }
  h2 {
    margin: 0px 30px;
  }
  h3 {
    margin: 30px;
  }
}
</style>