<template>
    <div class="protocole">
      <Protocole/>
    </div>
  </template>
  
  <script>
  import Protocole from '@/components/Protocole.vue'
  
  export default {
    name: 'ProtocoleView',
    components: {
      Protocole
    }
  }
  </script>