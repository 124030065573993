<template>
  <div v-if="artist" class="details-grid">
    <div class="one">
      <div class="macaron">
        <span><strong>Rendez-vous<br>pour la Nuit Blanche<br>le samedi 1er juin à la galerie Au Roi</strong>
        (75011) <strong>pour l’annonce des<br>résultats !</strong> (22h00)</span>
      </div>
      <div class="slider">
        <a
          v-for="(artistimage, index) in artist.images.data"
          :href="`#slide-${index + 1}`"
          :key="index"
          @click.stop.prevent="scrollToSlide(index + 1)"
          >{{ index + 1 }}</a
        >
        <div class="slides">
          <div
            v-for="(artistimage, index) in artist.images.data"
            :key="index"
            :id="`slide-${index + 1}`"
          >
            <img :src="server + artistimage.attributes.url" />
          </div>
        </div>
      </div>
      <div style="display: flex">
        <h2>{{ artist.name }}</h2>
        <div class="icons">
          <a v-if="artist.site" :href="artist.site" target="_blank"
            ><img src="@/assets/website.png" /></a
          ><a v-if="artist.instagram" :href="artist.instagram" target="_blank"
            ><img
              v-if="artist.instagram"
              style="margin-left: 8px"
              src="@/assets/instagram.png"
          /></a>
        </div>
      </div>
      <div
        class="cartel"
        v-html="artist.cartel"
        :class="{ 'p-no-margin': true }"
      />
      <p v-html="artist.description" />
      <button @click="toggleText" style="margin-bottom: 24px;">quelques suggestions de troc ici</button>
      <div ref="section1"><p class="needs" v-if="showText" v-html="artist.besoins"></p></div>
    </div>
    <div class="four"><h4 style="background-color: #33ff00; font-weight: normal; padding: 34px">
      <strong>Propositions de troc en ligne fermées</strong>, rendez-vous à la galerie Au Roi (73 rue de la fontaine au roi), pour faire vos offres.<br><br><strong>Annonce des résultats sur place à 22h00 !</strong>
    </h4></div>
  </div>
</template>

<script setup>
import { ref, watch, nextTick } from "vue";
import { useRoute } from "vue-router";

const server = process.env.VUE_APP_SERVER;
const token = process.env.VUE_APP_BEARER_TOKEN;

const route = useRoute();

const loading = ref(false);
const artist = ref(null);
const error = ref(null);

const showText = ref(false);

const section1 = ref(null);

watch(() => route.params.id, fetchData, { immediate: true });

function toggleText() {
  showText.value = !showText.value;
  if (showText.value && window.innerWidth > 860) {
  scrollToSection('section1');
  }
}

async function getDetails(id) {
  fetch(`${server}/api/artistes?filters[slug][$eq]=${id}&populate=*`, {
    method: "get",
    headers: {
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      var cartel = data.data[0].attributes.cartel;
      data.data[0].attributes.cartel = addInlineCssToInnerHtml(
        cartel,
        " class='p-no-margin'"
      );
      artist.value = data.data[0].attributes;
    });
}

function scrollToSlide(slideNumber) {
  const slideId = `slide-${slideNumber}`;
  const slideElement = document.getElementById(slideId);
  if (slideElement) {
    slideElement.scrollIntoView({ behavior: "smooth" });
  }
}

const scrollToSection = (section) => {
  const element = eval(section);
  if (element.value) {
    nextTick(() => {
      element.value.scrollIntoView({ behavior: 'smooth' });
    });
  }
};

async function fetchData(id) {
  error.value = artist.value = null;
  loading.value = true;

  try {
    artist.value = await getDetails(id);
  } catch (err) {
    error.value = err.toString();
  } finally {
    loading.value = false;
  }
}

function addInlineCssToInnerHtml(texte) {
  var modifiedHTML = texte.replace(/<p\b[^>]*>/gi, "<span>");
  modifiedHTML = modifiedHTML.replace(/<\/p\s*>/gi, "</span><br>");
  return modifiedHTML;
}

</script>
    
<script>
export default {
  name: "ArtistsPage",
};
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.confirmation-text {
  padding-top: 30px;
}

.form input[type="file"] {
  padding: 0px;
  max-width: 70vw;
  display: flex; /* Utilisation d'une largeur maximale */
  /* Autres styles personnalisés si nécessaire */
}

.filename {
  font-family: "Roboto";
}

#myFile {
  margin-bottom: 8px;
}

.details {
  font-size: 12px;
  font-family: "Roboto";
  margin: 40px 0px;
}

.macaron {
  margin: auto;
  animation: flicker 1.5s infinite alternate;
  margin-bottom: 30px;
  text-align: center;
  font-size: 12px;
  height: 160px;
  width: 160px;
  padding: 12px;
  /* background-color: #33ff00; */
  border: 2px solid #33ff00;
  border-radius: 100%;
  display: flex;
  justify-content: center;
}

.macaron span {
  margin: auto;
}

/* Customize the label (the container) */
.cgu-container {
  font-family: "Roboto";
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cgu-container #text {
  font-size: 12px;
}

/* Hide the browser's default checkbox */
.cgu-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.cgu-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: white;
  border: 1px solid black;
}

/* On mouse-over, add a grey background color
.cgu-container:hover input ~ .cgu-checkmark {
  background-color: #ccc;
} */

/* When the checkbox is checked, add a blue background */
.cgu-container input:checked ~ .cgu-checkmark {
  background-color: black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.cgu-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.cgu-container input:checked ~ .cgu-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.cgu-container .cgu-checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.needs {
  margin-bottom: 40px;
  font-family: "Roboto";
  font-size: 16px;
  color: #0500ff;
}

.icons {
  display: flex;
  margin: auto 16px auto auto;
  justify-content: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
}

button {
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
  border: none;
  color: white;
  font-family: "Roboto";
  width: 100%;
  height: 50px;
  background-color: #0500ff;
  border-radius: 100px;
}

button:hover {
  border: 1px solid #0500ff;
  color: #0500ff;
  background-color: white;
}

.icons img {
  height: 30px;
  width: 30px;
}

.cgu span {
  font-family: "Krub";
  font-size: 16px;
  margin: 0px;
}

#cgu:checked {
  background-color: blue;
}

.cartel {
  font-family: "Krub";
  font-size: 14px;
  margin: 0px;
  padding: 0px;
}

.details-grid {
  max-width: 1200px;
  margin: auto;
  margin-top: 40px;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  grid-template-columns: 40% 40%;
}
.one {
  grid-column: 1;
  text-align: left;
}
.two {
  text-align: left;
  grid-column: 1;
}
.three {
  justify-content: center;
  align-content: center;
  grid-column: 1;
}

.four {
  grid-row: 1;
  grid-column: 2;
  margin-bottom: 120px;
}
.four form {
  font-size: 18px;
  width: 100%;
  box-sizing: border-box; /* Include padding in the width calculation */
  display: flex;
  flex-direction: column; /* Ensure inputs stack vertically */
  align-items: left; /* Align inputs in the center horizontally */
}

.form {
  border: 1px solid black;
  grid-column: 2;
  padding: 24px;
  background-color: #f5f5f5;
}

input,
textarea {
  padding-bottom: 5px;
  font-size: 18px;
  border: none;
  margin-top: 8px;
  margin-bottom: 16px;
  font-family: "Krub";
  padding: 4px;
}
textarea:focus,
input:focus {
  outline: none;
}
label {
  font-family: "Syne Tactile";
  text-align: left;
}

.five {
  animation: flicker 1.5s infinite alternate;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: normal;
  font-size: 24px;
  padding: 24px;
  border: 1px solid #33ff00;
  /* border: 2px solid #0500ff; */
  background-color: white;
  grid-column: 2;
  font-weight: bold;
  max-width: 250px;
  max-height: 80px;
  justify-self: center;
  align-self: center;
}

.five p {
  font-family: "Krub";
  font-weight: normal;
  margin: 0px;
  padding: 0px;
}

@keyframes flicker {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    box-shadow: 0 0 2px #fff, 0 0 5px #fff, 0 0 5px #33ff00, 0 0 10px #33ff00,
      0 0 20px #33ff00, 0 0 15px #33ff00, 0 0 10px #33ff00, 0 0 5px #33ff00;
  }
  20%,
  24%,
  55% {
    box-shadow: none;
  }
}

h2 {
  font-weight: normal;
}

p {
  font-family: "Syne Tactile";
  font-size: 20px;
}

.slide img {
  width: 100%;
  object-fit: cover;
}

.card {
  width: 100%;
  height: 200px;
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
  transition: transform 1s;
  text-align: center;
  font-weight: bold;
}

.card-face {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.card-front {
  border: 2px solid #0500ff;
  align-content: center;
}

.card-back {
  border: 2px solid #0500ff;
  align-content: center;
  transform: rotateY(180deg);
}

.card:hover {
  transform: rotateY(180deg);
  color: black;
}

/* @media (max-width: 1240px) {
  .details-grid { grid-template-columns: repeat(2, 1fr); }
} */
@media (max-width: 860px) {
  .details-grid {
    margin: 24px;
    grid-template-columns: repeat(1, 1fr);
  }
  .four {
    grid-column: 1; /* Span the full width */
    grid-row: auto; /* Remove explicit row assignment */
  }
  .five {
    grid-column: 1; /* Span the full width */
    grid-row: auto; /* Remove explicit row assignment */
  }
  .form input[type="file"] {
    font-size: 12px;
  }
  .macaron {
    margin-top: -40px;
  }
}

* {
  box-sizing: border-box;
}

.slider {
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.slides {
  display: flex;

  overflow-x: auto;
  scroll-snap-type: x mandatory;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  /*
  scroll-snap-points-x: repeat(300px);
  scroll-snap-type: mandatory;
  */
}
.slides::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.slides::-webkit-scrollbar-thumb {
  background: black;
}
.slides::-webkit-scrollbar-track {
  background: transparent;
}
.slides > div {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 100%;
  height: 500px;
  /* margin-right: 50px; */
  border-radius: 10px;
  background: white;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
}

.slides img {
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slider > a {
  font-family: "Roboto";
  display: inline-flex;
  width: 1.5rem;
  height: 1.5rem;
  background: white;
  color: black;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 0 0.5rem 0;
  position: relative;
}
.slider > a:active {
  top: 1px;
}
.slider > a:focus {
  color: white;
  background: #000;
}

/* Don't need button navigation */
@supports (scroll-snap-type) {
  .slider > a {
    display: none;
  }
}

html,
body {
  height: 100%;
  overflow: hidden;
}
body {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Ropa Sans", sans-serif;
}
</style>