<template>
    <div v-if="artists" class="artists-grid">
      <router-link :to="{ path: `/artistes/${artist.attributes.slug}`}"  v-for="(artist, index) in artists" :key="index"
        ><div class="artist-card" :style="{backgroundImage: `url(${'https://srv512313.hstgr.cloud' + artist.attributes.images.data[0].attributes.url})`}">
          <div
            class="artist-card-name"
          >
            {{ artist.attributes.name }}
          </div>
        </div></router-link
      >
    </div>
</template>

<script setup>
import { ref } from "vue";

const server = process.env.VUE_APP_SERVER;
const token = process.env.VUE_APP_BEARER_TOKEN;

const artists = ref(null);
fetch(`${server}/api/artistes?populate=images`, {
  method: "get",
  headers: {
    "content-type": "application/json",
    Authorization: token,
  },
})
  .then((response) => response.json())
  .then((data) => (artists.value = data.data));
</script>
  
<script>
export default {
  name: "ArtistsPage",
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
nav {
  margin: 0px;
  padding: 0px;
}
.nav-options {
  padding: 24px;
  width: 100%;
  align-items: center;
}
.artists-grid {
  max-width: 1000px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 120px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  grid-auto-rows: minmax(400px, auto);
  justify-content: center;
  align-content: center;
}
.artist-card {
  width: 100%;
  /* background-image: url("@/assets/oeuvre.png"); */
  background-size: cover;
  align-content: center;
  justify-self: center;
}
.artist-card-name {
  height: 80;
  background-color: white;
  border: 2px solid #33ff00;
  margin: auto 12px;
  padding: 12px 24px;
}

.artist-card:hover .artist-card-name {
  animation: flicker 1.5s infinite alternate;
}

a {
  display: inherit;
  text-decoration: none;
  color: black;
}

@media (max-width: 1040px) {
  .artists-grid {
    grid-template-columns: repeat(2, 1fr);
    margin: 0px 24px;
  }
}
@media (max-width: 600px) {
  .artists-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@keyframes flicker {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    box-shadow: 0 0 2px #fff, 0 0 5px #fff, 0 0 10px #33ff00, 0 0 20px #33ff00,
      0 0 40px #33ff00, 0 0 25px #33ff00, 0 0 15px #33ff00, 0 0 15px #33ff00;
  }
  20%,
  24%,
  55% {
    box-shadow: none;
  }
}
</style>